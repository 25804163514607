import React, { useState, useRef, useEffect } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import "./FaqComponent.css";

const faqs = [
  {
    question: "What is avijo’s view on data security and Privacy?",
    answer:
      "At avijo we take data security and privacy extremely seriously. It is one of the foundational pillars of our company and is implemented at the core of every product.\n\nWe believe that healthcare data is the most sensitive information about you and must receive appropriate protection. avijo collects or uses any personal or sensitive personal information belonging to you only after receiving appropriate and clear consent from you. Further, we understand that people change their minds, so no consent is permanent and our systems are built with the flexibility so that any consent given can later be revoked.\n\nThis is why all our products have features where patients and providers are in control and can decide what they want to share and what they prefer to keep private.",
  },
  {
    question: "What data does avijo have?",
    answer:
      "At the outset, our data is stored with 256 bit encryption at HIPAA compliant servers. Further, we are an ISO27001 certified company. This certification is one of the most recognized and stringent information security certification that validates a company’s efforts on protecting data and all kinds of information assets.\n\nWe have two distinct data sets. First is the data that healthcare providers use our software to store information regarding the patients they are treating. This can include information about the patient, their diagnosis, treatment plan, any clinical notes, communication and other details. All of this is stored on behalf of the provider, and avijo cannot access this. It is stored privately and securely for every provider who uses our software.\n\nThe other data set is when patients directly visit avijo and use avijo to store their health history or undertake a healthcare transaction, such as booking an appointment, online consultation and more. We store all this data on behalf of the patient and that too is stored with 256 bit encryption and HIPAA compliant servers. Any patient who uses our service, gives us permission to reach out to them from time to time with marketing and/or other communication which he/she can opt out of when he/she chooses so.",
  },
  {
    question:
      "I am a doctor using your Avijo software, what kind of access do you have to my data stored in Avijo?",
    answer: "avijo does not have access to the data stored in Avijo Export.",
  },
  {
    question:
      "If you don’t have any access to data in Avijo, how can you send those appointment confirmation or feedback collection SMSes to my walk-in patients?",
    answer:
      "To be clear – we build the technology that enables YOU to send the SMSes. Therefore, while our systems send the SMS, they can only be sent by the doctor explicitly allowing the system to do so. These can be toggled in the settings tab in your Avijo Export. Enabling this setting and/or does not give avijo access to any other aspect of your data other than what is required to complete sending of the SMS. It also does not give avijo permission for avijo to reach out to the patient for any other reason. In addition, all of this is done via an automated system with no human involvement or intervention possible.\n\nFor example, when you enable the settings and send an appointment confirmation SMS to your walk-in patient, the system will take phone number of that patient, locate the appointment detail that you have confirmed and send that information to that patient. Beyond this, avijo does not get any rights to send any other message or communication or to reach out to the patient for any reason whatsoever. Further, you can, at any time, revoke even this facility by simply changing the settings inside Avijo.",
  },
  {
    question:
      "How do you distinguish between patients who come to me directly and patients who come to me by booking via avijo’s website or app? For both of them, what data do you access?",
    answer:
      "Millions of patients and hundreds of thousands of providers trust us with their data. We take this responsibility extremely seriously and strive to make avijo the safest place for your health data.\n\nWe have always maintained a very clear distinction between data sets that pertain to users who directly visit avijo.in (“Online Patients”) and those that visit a clinic and are walk-in patients of the doctor (“Walk-in Patients”). Separated infrastructure and firewalls on Avijo prevent avijo.in from accessing data from Avijo Expert.\n\nOnline Patients: These are patients who register with Avijo Export either via avijo or our app and open the call or book an appointment with an affiliated clinic. Each of these patients, individually, give us permission to reach out to them with any communication that is relevant to the provided services as well as for offering new products or services. avijo does not have any access to patient’s personally identifiable health information.\n\nWalk-In Patients: avijo does not have access to information about patients that directly walk-in to the clinic and the doctor inputs their data into our software such as Avijo. Inputting patient data into Avijo does not give avijo rights to reach out to that patient. Further, avijo also does not have access to any personally identifiable health information for those patients either. We think this is really important and have therefore committed to every provider by writing this down in our terms of service.",
  },
  {
    question:
      "When I ask my walk-in patients to give me a feedback-does that make them avijo’s direct online patients?",
    answer:
      "There is no way your walk-in patient will receive promotional communication from avijo. The only way it is possible is if this patient who has been a walk-in patient at your clinic, later independently visits avijo.in and signs up for an account with Avijo Export. At this point, he gives us his/her permission that avijo can reach out to him with promotional material. Only once we get this permission directly from the patient when he decided to visit our website do we reach out to him/her.\n\nUnless your walk-in patient visits avijo independently and gives us permission, no marketing communication is received by him from avijo. The only communication they will receive will be what you have enabled in your settings in Avijo. If you’d like to review these settings you can click here to log in to Avijo Export and review your settings.",
  },
  {
    question:
      "My patients complain of receiving marketing communication from other healthcare companies as soon as they are registered at my clinic. Do you sell data?",
    answer:
      "Never. We do not sell any patient data – whether it is for walk-in patients or for our online patients with any third party. We also do not allow third parties to market to any user of Avijo Export. We are not responsible for any promotional communications received by patients from other vendors. We recommend that you should ask the patients to immediately report such marketing campaigns to TRAI for necessary action by the regulator.",
  },
  {
    question:
      "My offline patients receive SMS from avijo software which mentions avijo and that leads them to your website/app. They are not your direct online patients. How can you market to them?",
    answer:
      "We have some services – such as appointment reminders or electronic record sharing where a doctor can share records with their patients. When a doctor does that, we send a message to the patient with a link to access that record. However, if the doctor does not want such a link to be included in those SMS, he/she can opt out of it.",
  },
  {
    question: "Have you ever faced a data breach?",
    answer:
      "No we have not. We will continue to work very hard to make sure that data stored with avijo remains secure.",
  },
  {
    question:
      "I have been asking my patients to go to avijo and book appointment with me there. They are still my patients and not avijo’s, hence can you market to them?",
    answer:
      "Merely visiting avijo is not sufficient. To receive marketing messages from avijo, a patient has to visit us, register for an account and give us permission to market them. Only if they give us their permission do we market to them.\n\nFurther, the database containing Avijo data is separate from that used for avijo.in. As per our terms of service agreed with you, avijo cannot access the data stored in the Avijo database. Hence, we are unable to do/duplicate anything patients visiting avijo.in may have, in the past, visited you and whose information may be available in Avijo database as that would be breach of privacy and of our contract with you.",
  },
  {
    question:
      "When I send my patients a prescription through Avijo and when they open it, does that make them avijo’s direct online patients?",
    answer: "No. It does not.",
  },
  {
    question:
      "If a Walk-in Patient downloads and signup through avijo app to view prescription etc. shared by the doctor does he/she becomes Online Patient?",
    answer:
      "When you share a prescription with a patient, he does not need to download the avijo app to see it. He can simply click on the link you share with him and view the prescription directly. If a patient downloads our app, and gives us permission to reach out to directly, only then can we do so.",
  },
  {
    question: "Is my data really safe with avijo?",
    answer:
      "Absolutely. avijo is amongst the safest places for you to store your healthcare information and that of your patients.\n\nWe have a variety of measures that protect your data, some of which are:\n\n1. HIPAA Compliant Servers: All data is stored in HIPAA compliant servers\n2. Encryption: All data is encrypted with 256-bit encryption during transit and at rest.\n3. Two Factor: We have implemented Two-factor authentication to protect against foul-play.\n4. Access Zones: We have implemented access zones that prohibit access to information from locations not specified by the user. This ensures that even if the authentication information leaks, access can only happen from the physical locations specified by the user.\n5. Role Based Profiles: A doctor/clinic owner can set up different profiles for their staff with different levels of information access. This ensures that only the doctor has access to the patient files while the staff access is restricted to the clinic operations rather than the patient information.\n6. Data Backup: We take multiple backups of your data and it is kept in geographically distributed locations to make sure you never have any data loss. Even in the event of a natural disaster in one geography, your data remains safe and can be recovered.\n7. No Virus: Since all your data is stored in a cloud, it protects you from any local virus that your computer might have, so the only virus you have to deal with is those affecting your patients :)",
  },
  {
    question:
      "What specific measures do you use to ensure security of data stored with you?",
    answer:
      "We have a variety of measures that protect your data, some of which are:\n1. HIPAA Compliance: All data is stored in HIPAA compliant servers ensuring industry standard consent architecture and privacy policies.\n2. Encryption: All data is encrypted with 256-bit encryption during transit and at rest.\n3. Two Factor: We have implemented Two-factor authentication to safeguard against foul play.\n4. Access Zones: We have implemented access zones that prohibit access to information from locations not specified by the user. This ensures that even if the authentication information leaks, access can only happen from the physical locations specified by the user.\n5. Role Based Profiles: A doctor/clinic owner can set up different profiles for their staff with different levels of information access. This ensures that only the doctor has access to the patient files while the staff access is restricted to the clinic operations rather than the patient information.\n6. Data Backup: We take multiple backups of your data to make sure you never have any data loss and even in terms of a natural disaster in one geography, your data can be recovered.",
  },
  {
    question:
      "What if I find a security vulnerability in any of your applications?",
    answer:
      "In the unlikely event that you discover a vulnerability, we do have a responsible security disclosure program that prescribes next course of action and we would love to hear from you and fix it at the earliest. Please adhere our Responsible Disclosure Policy and report them to us on secure@avijo.in.",
  },
  {
    question:
      "Is avijo compliant with the data security and privacy laws in India?",
    answer:
      "Of course, avijo complies with all the applicable laws in every country it operates in.",
  },
];

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    if (activeIndex !== null && contentRefs.current[activeIndex]) {
      contentRefs.current[
        activeIndex
      ].style.maxHeight = `${contentRefs.current[activeIndex].scrollHeight}px`;
    }
    if (activeIndex === null) {
      contentRefs.current.forEach((ref) => {
        if (ref) {
          ref.style.maxHeight = "0px";
        }
      });
    } else {
      contentRefs.current.forEach((ref, idx) => {
        if (idx !== activeIndex && ref) {
          ref.style.maxHeight = "0px";
        }
      });
    }
  }, [activeIndex]);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => handleToggle(index)}>
            <span>{faq.question}</span>
            <span className="faq-icon">
              {activeIndex === index ? <FaMinus /> : <FaPlus />}
            </span>
          </div>
          <div
            ref={(el) => (contentRefs.current[index] = el)}
            className={`faq-answer ${activeIndex === index ? "open" : ""}`}
          >
            <p>{faq.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Faq;
