import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import "./ProfileEdit.css"; // Import your CSS file here
import { BaseUrl } from "../../Routes/BaseUrl";

const ProfileEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialFormData = location.state || {
    title: "",
    specialization: "",
    experience: "",
    gender: "",
    fullName: "",
    dateOfBirth: "",
    degree: "",
    collegeUniversity: "",
    year: "",
    city: "",
    colonyStreetLocality: "",
    country: "",
    pinCode: "",
    state: "",
    registrationNumber: "",
    registrationCouncil: "",
    registrationYear: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for required fields
    const requiredFields = [
      "title",
      "specialization",
      "experience",
      "gender",
      "fullName",
      "dateOfBirth",
      "degree",
      "collegeUniversity",
      "year",
      "city",
      "colonyStreetLocality",
      "country",
      "pinCode",
      "state",
      "registrationNumber",
      "registrationCouncil",
      "registrationYear",
    ];

    for (let field of requiredFields) {
      if (!formData[field]) {
        Swal.fire({
          icon: "error",
          title: "Missing Fields",
          text: "Please fill out all required fields!",
        });
        return;
      }
    }

    try {
      const response = await axios.post(
        BaseUrl + "/doctor/doctorProfile",
        formData
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Profile Updated",
          text: "Your profile has been updated successfully!",
        }).then(() => {
          navigate("/Verfied");
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text:
          error.response?.data?.message ||
          "An error occurred while submitting your profile.",
      });
    }
  };

  return (
    <>
      <div className=" mt-3">
        <form onSubmit={handleSubmit}>
          <section className="profile-edit navbar-shadow py-4">
            <div className="container mt-3">
              <div className="">
                {" "}
                <div className="d-sm-block d-md-flex align-items-center gap-5">
                  <div className="text-center">
                    <div>
                      <label>Profile photo</label>
                    </div>
                    <div>
                      <img
                        src={require("../../Assets/image/Frame 2609503.png")}
                        alt=""
                      ></img>
                    </div>
                    <div className="mt-2">
                      <button type="button" className="rounded">
                        Upload Photo
                      </button>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <label>FULL NAME</label>
                    <input
                      type="text"
                      name="fullName"
                      placeholder="Adi Taygi"
                      value={formData.fullName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="profile-edit navbar-shadow py-4">
            <div className="container mt-3">
              <div className="">
                <div className="basic mt-3">
                  <h5>Basic Details</h5>
                  <div className="row">
                    <div className="col-md-4">
                      <div>
                        <label className="mb-2">Title</label>
                        <input
                          type="text"
                          name="title"
                          placeholder="Enter your title"
                          value={formData.title}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label className="mb-2">Gender</label>
                        <select
                          name="gender"
                          value={formData.gender}
                          onChange={handleChange}
                        >
                          <option value="">Select your Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <label className="mb-2">Specialization</label>
                        <select
                          name="specialization"
                          value={formData.specialization}
                          onChange={handleChange}
                        >
                          <option value="">Select Specialization</option>
                          <option value="Cardiology">Cardiology</option>
                          <option value="Dermatology">Dermatology</option>
                          {/* Add more specializations as needed */}
                        </select>
                      </div>
                      <div>
                        <label className="mb-2">Date of Birth</label>
                        <input
                          type="date"
                          name="dateOfBirth"
                          value={formData.dateOfBirth}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <label className="mb-2">Experience</label>
                        <input
                          type="number"
                          name="experience"
                          placeholder="Enter years of experience"
                          value={formData.experience}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label className="mb-2">Country</label>
                        <input
                          type="text"
                          name="country"
                          placeholder="Enter your country"
                          value={formData.country}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="profile-edit navbar-shadow py-4">
            <div className="container mt-3">
              <div className="">
                <div className="basic mt-3">
                  <h5>Education Details</h5>
                  <div className="row">
                    <div className="col-md-4">
                      <div>
                        <label className="mb-2">Degree</label>
                        <input
                          type="text"
                          name="degree"
                          placeholder="Enter your Degree"
                          value={formData.degree}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <label className="mb-2">College / University</label>
                        <input
                          type="text"
                          name="collegeUniversity"
                          placeholder="Enter your College / University"
                          value={formData.collegeUniversity}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <label className="mb-2">Year</label>
                        <input
                          type="number"
                          name="year"
                          placeholder="Enter Year"
                          value={formData.year}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="profile-edit navbar-shadow py-4">
            <div className="container mt-3">
              <div className="">
                <div className="basic mt-3">
                  <h5>Address</h5>
                  <div className="row">
                    <div className="col-md-4">
                      <div>
                        <label className="mb-2">City</label>
                        <input
                          type="text"
                          name="city"
                          placeholder="Enter your City"
                          value={formData.city}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label className="mb-2">Pin Code</label>
                        <input
                          type="text"
                          name="pinCode"
                          placeholder="Enter your Pin Code"
                          value={formData.pinCode}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <label className="mb-2">
                          Colony / Street / Locality
                        </label>
                        <input
                          type="text"
                          name="colonyStreetLocality"
                          placeholder="Enter your Colony / Street / Locality"
                          value={formData.colonyStreetLocality}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label className="mb-2">State</label>
                        <input
                          type="text"
                          name="state"
                          placeholder="Enter your State"
                          value={formData.state}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="profile-edit navbar-shadow py-4">
            <div className="container mt-3">
              <div className="">
                <div className="basic mt-3">
                  <h5>Medical Registration</h5>
                  <div className="row">
                    <div className="col-md-4">
                      <div>
                        <label className="mb-2">Registration Number</label>
                        <input
                          type="text"
                          name="registrationNumber"
                          placeholder="Enter your Registration Number"
                          value={formData.registrationNumber}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <label className="mb-2">Registration Council</label>
                        <input
                          type="text"
                          name="registrationCouncil"
                          placeholder="Enter your Registration Council"
                          value={formData.registrationCouncil}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <label className="mb-2">Registration Year</label>
                        <input
                          type="text"
                          name="registrationYear"
                          placeholder="Enter Registration Year"
                          value={formData.registrationYear}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="profile-edit navbar-shadow py-4">
            <div className="container mt-3">
              <div className="">
                <div className="basic mt-3">
                  <h5>Identity Proof</h5>
                  <div className="row">
                    <div className="col-md-4">
                      <div>
                        <select
                          name="identityProof"
                          value={formData.identityProof}
                          onChange={handleChange}
                        >
                          <option value="">Document to be uploaded</option>
                          <option value="Aadhar Card">Aadhar Card</option>
                          <option value="Pan Card">Pan Card</option>
                          <option value="Voter Card">Voter Card</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="text-center">
                        <h5 className="mb-2">Upload here</h5>
                        <button
                          type="button"
                          className="rounded py-2 d-flex justify-center"
                        >
                          <svg
                            width="34"
                            height="30"
                            viewBox="0 0 34 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M32 13.3327V24.9994C32 26.8404 30.5077 28.3327 28.6667 28.3327H5.33333C3.49238 28.3327 2 26.8404 2 24.9994V9.99935C2 8.1584 3.49238 6.66602 5.33333 6.66602H7.83333C8.88252 6.66602 9.87048 6.17203 10.5 5.33268L12.25 2.99935C12.8795 2.16 13.8675 1.66602 14.9167 1.66602H19.0833"
                              stroke="white"
                              stroke-width="2.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M27.8327 1.66602V5.83268M27.8327 5.83268V9.99935M27.8327 5.83268H23.666M27.8327 5.83268H31.9993"
                              stroke="white"
                              stroke-width="2.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M17.0007 23.3333C20.6826 23.3333 23.6673 20.3486 23.6673 16.6667C23.6673 12.9848 20.6826 10 17.0007 10C13.3188 10 10.334 12.9848 10.334 16.6667C10.334 20.3486 13.3188 23.3333 17.0007 23.3333Z"
                              stroke="white"
                              stroke-width="2.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="text-center mt-4">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProfileEdit;
