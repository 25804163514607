import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Link, useNavigate } from "react-router-dom";
import ExpertNav from "../components/Navbar/ExpertNav";
import Footer from "../components/Home/footer/Footer";



const AvijoExpert = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className="App">
            <ExpertNav />
            <Header />
            <Carousel />
            <SupportData />
            <Footer />
        </div>
    )
}

const Header = () => (
    <header className="hero bg-gradient-to-r from-[#ECF9FF] to-[#C3EBFE]">
        <div class="flex flex-col md:flex-row justify-between p-4">
            <div className="flex flex-col p-2 w-full md:w-1/2 h-full">
                <div className="flex-grow">
                    <div className="flex flex-row items-end">
                        <h1 className="font-semiBold text-[45px] text-[#535353]" style={{ fontFamily: 'Gilroy-SemiBold' }}>avijo</h1><h6 className="text-xl text-[#FD7979] italic">Expert</h6>
                    </div>
                    <span className="font-gilroy font-[500] text-xl text-[#535353]">
                        Avijo Expert is the premier applications designed specifically for healthcare professionals. Built to complement the Avijo Alpha platform, Avijo Expert empowers doctors and medical practitioners with the tools they need to manage their practice, engage with patients, and contribute to a growing health community—all from the convenience of their devices.
                    </span>
                </div>
                <button className="bg-[#0097DB] h-10 w-[130px] rounded-lg mt-4">
                    <span className="text-sm">Get Started</span>
                </button>
            </div>
            <div className="p-4 w-full md:w-1/2 md:ml-20">
                <img src='https://s3-alpha-sig.figma.com/img/0cbe/98ab/c2861e595da7ab1cc69f8cbad9188dc0?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=psLJ5ODWqF7ityUqp5olmK2qFEIpGjfa~TF6-w0Um-K8Fa1oPmaE2CwfR4Etlmt-yINXD5NgWdDZZPYxkXF8vbdU6jRF5-D2Ktl6jejyBlBOe7Y0j6~wCssE5aRb-qwENkONt1m60~zd6L7Cbo6krliecc2E7iCxGJDJ4PyEsO~8H1c3auRl0qJmy-hs9yGh15HWTBvB5NtlTmg7XjylVluU4oVYCBbsENUY50rGlEp26~at6c6n0iFhVcHPLGh6AjkEJb2iByNwnFtV7Ep63KrMd7mqTeTG1qpVeni6iOStyOYbqzfsomnWrKO5n9tIFBnLuUj0KdACzXrTR9apTA__' alt="header" className="h-60 w-90" />
            </div>
        </div>
    </header>
);

const CarouselItem = ({ active, index, title, content, buttonText, imgSrc, bgColor, setActive }) => (
    <div className={`${active === index ? 'active' : ''} carousel-item flex flex-col items-center`} style={{ backgroundColor: bgColor }}>
        <h2 className="self-center text-center ml-[20%] w-[60%] pt-5 text-4xl">Elevate Your Medical Practice with Cutting-Edge Technology</h2>
        <div className="flex flex-col md:flex-row items-center justify-between mt-5 w-[90%] text-center ml-10">
            {['Patient care', 'Remote Working', 'AI optimize', 'Online Reach', 'Docare'].map((text, idx) => (
                <div key={idx} onClick={() => setActive(idx)}>
                    <span className={`font-semibold text-[22px] text-center hover:text-white ${active === idx ? 'text-[#0097DB]' : 'text-black'}`}>{text}</span>
                </div>
            ))}
        </div>
        <div className="flex flex-col md:flex-row justify-between p-4 pt-2 items-center">
            <div className="flex flex-col p-2 w-full md:w-[65%] h-full">
                <div className="flex-grow">
                    <h1 className="text-[36px] font-gilroy font-semiBold text-black ml-4">{title}</h1>
                    {content.map((item, idx) => (
                        <div key={idx} className="flex flex-row items-start mt-2">
                            <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" alt="arrow" />
                            <span className="text-black text-[20px] font-gilroy font-mdeium">{item}</span>
                        </div>
                    ))}
                </div>
                <button className="bg-[#0097DB] p-2 w-[220px] rounded-lg mt-4">
                    <span className="text-sm text-white">{buttonText}</span>
                </button>
            </div>
            <div className="p-4 w-full md:w-[500px] h-[430px] flex justify-center">
                <img src={imgSrc} alt="header" className="h-[80%] w-[80%]" />
            </div>
        </div>
    </div>
);

const Carousel = () => {
    const [active, setActive] = useState(0);

    const items = [
        {
            index: 0,
            title: 'Comprehensive Patient Management',
            content: [
                'Centralized platform for managing patient records, appointments, and communications.',
                'Easy access to patient history and diagnostic reports for informed decision-making',
                'Secure storage and retrieval of patient data compliant with ABDM standards.',
            ],
            buttonText: 'Register as HFR for demo',
            imgSrc: 'https://s3-alpha-sig.figma.com/img/4617/de20/481045b8c3ebcf2928412728e4ac804a?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=XaIbPL7LXp5X63Fyf-H0-l~mE45MG19N4bzMTnNw0LsEf3llQFz8wvI1c59AuNR8CAG685kFlVtvYxeDzESiUHToKjKN3CpHKUxwEKV~EwFCeqNhTW2AkL24kKvZaoYKyyEy6gvDx8IoXhkwKy6Fq0MMQ83NvJ3K6i9kGLzpl7fa-3edC8z6Y6UakXDMnhTZkJe6K1KfPgbwmIuep77L3m5NXQvhnjTyhXoVqndBO8pp~TuNgwryGWe8nt4aXAXADMCCJVSpnRieaefyhWLJMiu-kQXvdSbaEa1jsZTfs5zfiv~T-G4k0q9JX7LaE6IxsST~9Di0ThdwmuinwkI-NA__',
            bgColor: '#FCEFE7',
        },
        {
            index: 1,
            title: 'Teleconsultation Integration',
            content: [
                'Seamless video, audio, and chat-based consultation options.',
                'Integrated prescription generation and sharing with patients.',
                'Allows for remote diagnosis and follow-up, expanding patient reach.',
                'Real-time appointment booking and management.',
            ],
            buttonText: 'Learn More',
            imgSrc: 'https://s3-alpha-sig.figma.com/img/8726/7fbc/803c58c63dcad6bda70802ffa3ef77d6?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bCkp1IgL~Oom6kwV2DMhxcRzhwjTQtV5VRM5Dk10ssF~W5DS9st1VuM~XrbUrQAjqA9vxgWCNuZu5e6zCfTs81h6jgjlBjLfvewxYNMi6beBso0-NsVViV~Z31QpEvgKVAAx7T~7lpiBFpW8khOh9L9TRw0rcOcfM2Q2TwdzuUToiZbVqt~XbhDnT36bpNWsuoyRJfBmcghk1GYfrjkJNoUHBVg~z4a5Eokv2xNhpPdaWy5wxrISXxCDYNq3qge7qwLyNvm9WS~CXdJM1HPUsl~SobPD-DV5bSywewXdhS7l9OS6DtCFm6bZo73691RJY702CLHpF2gPa33fhi2S9g__',
            bgColor: '#FAEFD4',
        }, {
            index: 2,
            title: 'AI-Powered Insights and operation',
            content: [
                'Utilize AI for patient treatment recommendations and insights..',
                'Analyze patient data for trends and predictive healthcare outcomes..',
                'Support clinical decisions with AI-driven diagnostic tools..',
            ],
            buttonText: 'Register as HFR for demo',
            imgSrc: 'https://s3-alpha-sig.figma.com/img/073d/b412/689224b2ce2eb402ec6f418fac14f228?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=IrSh48hnBAhDUSFV~GjEFm5nTIW16071wR6OxSbUhgV8vxyxMksskwA4nmpOnuGDedWGXNAUHGwuaT3ybC9inO9x2iUS~uw3o5RrtfXFU~sY404oyPRriwrmnvCJlhqxyDETEopoT7r2yXEhtERNNtOhGnBYqw6vOAC1qxS1qpnXHzeJQJXyax8vjhMeL0hzcSuwh9kAzOiKh-ReIK909xleo4cbW78MMgFtJB51E5G~mCHh3ws6MLvSS1xewASqFps5mug-xdKlz3kHUVz6eQJo0yosMfI9ddplOW6UBbezFPkRnVE6M99Glekc85XbNbNLb3oTt8nczXzzmCjI0g__',
            bgColor: '#E4DDFF',
        }, {
            index: 3,
            title: 'Teleconsultation & Appointment Booking',
            content: [
                'Offers a user-friendly interface for patients to book appointments online.',
                'Supports video, audio, and chat-based consultations',
                'Provides a secure platform for sharing medical reports and prescriptions.',
                'Integrates with electronic health records for seamless consultations',
            ],
            buttonText: 'Register as HFR for demo',
            imgSrc: 'https://s3-alpha-sig.figma.com/img/7bf0/edaf/67da5029bab794b24d074b2ffb2f22c2?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gpJ9tiLNwYCWqRx52jKMtBCAuF-fYl9VlbL~AGi4-Df06elwf8rKJRdYRD0L~3paRSsmCmBQg1I7LtrD0jn9NgoxDnITFdYmw-ZZNjrbfIh4-R6dvcvOG5Jk6mV5h4j75BCLTLcKmhJUCzolkZqrTiEbJtY-AYqmkpuTQ4G00~Frq-vMzix7A5YxQc7c0c-TRz6g1XYnRr9eH-UKlSd2lP~EKNJrUCeLZL9nRZ-rrl4dnTBt8baqVdmnD~UxHgWkWqjtv5yNsTfINk0-lX18Tb41cMNE2DflKeqnNlMnwP9ONpoJ0rGZjwubZjG8Xd1WhaVJG8lnrCjEe6a2-~kt7Q__',
            bgColor: '#E0EBFF',
        }, {
            index: 4,
            title: 'Doctor & Patient Community Integration',
            content: [
                'Enables doctors to build their professional network within the healthcare community',
                'Facilitates case discussions and peer reviews in a secure environment',
                'Offers a platform for collaborative learning and knowledge sharing',
                'Encourages patient engagement through Q&A sessions with doctors.',
            ],
            buttonText: 'Register as HFR for demo',
            imgSrc: 'https://s3-alpha-sig.figma.com/img/cad1/c260/4c816eedb87232176863b5a261079d2f?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=CN5wC~yvYn4kJbVetYQqas92lNxsfLh4EY~B4gGPG08C54I7zlEf3J6CEuhhIn63QHS4p-qrQuqc9WbrnRzjF6vN3qBVEn1AP852JIGR0oAl7cfFZNFgR8Yh4oUtoz6s314QPdECIxUkMqgpyQuTnFQ7fZlAmSqcTfvfpe62R448lRyq2c0Ekc1wlDTWy5ZGYEZgH7RWroaRdRq8hWdD8Gjbbaexj5DQVQiuNrfcya4bSo2fXMgcvDZT5-h~uP9NyDzQGgy262szF5VRwvXvlSgPGCCenkqDYeqqjlDcwESU-4h5az2ofOP~SfPwPrAMbxesalCHfBxboHOT0VWIZQ__',
            bgColor: '#FADCFF',
        }
    ];

    const handlePrev = () => {
        setActive(prev => (prev > 0 ? prev - 1 : items.length - 1));
    };

    const handleNext = () => {
        setActive(prev => (prev < items.length - 1 ? prev + 1 : 0));
    };

    return (
        <div id="carouselExample" className="relative">
            <div className="carousel-inner">
                {items.map(item => (
                    <CarouselItem
                        key={item.index}
                        active={active}
                        index={item.index}
                        title={item.title}
                        content={item.content}
                        buttonText={item.buttonText}
                        imgSrc={item.imgSrc}
                        bgColor={item.bgColor}
                        setActive={setActive} // Pass setActive function
                    />
                ))}
            </div>
            <button
                className="absolute top-1/2 left-0 transform -translate-y-1/2 p-2"
                onClick={handlePrev}
                style={{ backgroundColor: 'transparent' }}
            >
                <img src={require('../Assets/image/left.png')} alt="Previous" className="w-8 h-8" />
            </button>
            <button
                className="absolute top-1/2 right-0 transform -translate-y-1/2 p-2"
                onClick={handleNext}
                style={{ backgroundColor: 'transparent' }}
            >
                <img src={require('../Assets/image/right.png')} alt="Next" className="w-8 h-8" />
            </button>
        </div>
    );
};


const SupportData = () => {

    const data = [
        {
            id: 0,
            heading: 'Profit & Growth Opportunities',
            image: 'https://s3-alpha-sig.figma.com/img/050a/a594/25ad2f16d7fa6745f3d6fecf67440a5c?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=I4JwTxylzbANpTaPY9s23yVihoyYxbFiwGmPiesridvPfgVcBi9idVspJsjJg5wSTE1iyNGjheqRZMbcM--BnV-RkNTpLCz7uED9SD8IhZLgRZX-UnkAvJtsfGw8DPuevN29DcfXFwCcrTY8CQw~mMyIna1eYXwwFNiZ1s~avITajhte9iUgkD1AgWTTKD-Pk0gex11cCf6Y3Oq2RDL0hq-JdGRoIcU4jM600rXuZ52G4s5Tsvsng5zXRklpwXUqfN9boQcwc1Fd25dSsmSK6qD9WH9-AO7cbBB0WHuJ91bjMDsdHBY~~-6LLP1e3XukgUmJdHBX56qtYtxDiqM9eg__',
            points: [
                {
                    id: 0,
                    text: 'Avijo Expert isn’t just about managing your practice—it’s about growing it. The app provides opportunities for additional revenue through profit margins on diagnosis and medicine prescriptions. You can also participate in our affiliate program, recommending trusted healthcare products to your patients through the Avijo platform, and earning commissions on every sale.'
                }
            ]
        },
        {
            id: 1,
            heading: 'Join the Docare Community',
            image: 'https://s3-alpha-sig.figma.com/img/7c91/b4dd/12703bb802bdfefddc314923ecc6d76a?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=qQyeeqB08qughhik0VrqYlx~wUrqrfcuBH9VLQz09mx05yO1glf8TtcYgIDGyFmKU6Kpk3OhBYLeNbRFv8zs2rphM87YmHC9pPK4DSQNFflrzXjN32~m53GdaSTRw7qZkoVUMT41~fyBxevKIjxmUSMfxX0MI41lISuVoz6EqxiPKYwI9PSbtQHC4d6bEMrJDQTFxZEVKFs6q4vA-8nvinnnAHiiBjcIuz1FxHIc5d07ek6AaYhiUAhGJ3XtS5VSJc2pVorUqWw46EtQ0Gtk5s36a4ztixc2sEG0VgiriO-1kpzvaPdhk0UfwkapGYs4oOjI2Z5BdjapuB3-iKwfqw__',
            points: [
                {
                    id: 0,
                    text: 'Enhance your professional presence by joining Docare, Avijo’s health community. As part of Docare, you can create and share health-related content, engage in meaningful discussions with other healthcare professionals, and increase your visibility to patients seeking expertise in your specialty. Whether you’re looking to educate the public, share insights with peers, or simply network, Docare offers a platform to amplify your voice .'
                }
            ]
        },
        {
            id: 2,
            heading: 'Get Started with Avijo Expert',
            image: 'https://s3-alpha-sig.figma.com/img/580b/3fb4/8e465324458cadb0d32ea32300a280b6?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Ti7IomILB75ED1dvE5V-r5dtWJPGXbmPDzL~ghFC1W4vNgAtNcpR6BJox1eed3KhHIl~Too2d5zgU~3R9ryYABM5l5Ye79Kag6SAG0O7nIGNzrtdc33A9cB9fc9VH8Hx0mX4E60d-Xp9rQ35Zx45nMwzLKYSLSKeHuRH2PxZ7bQQlmhN-5GPs7DskwrPQ7T5hsy9T--LCEUZzcAXVz063YFT4dAPibZ7c02Xac7RcSX6zX7~I~iiRDbtw6UQGsbV1lQhpxRDHxRq3CxkJ2Ysvq-NCG6xE5q6UGWoJZWQBOARjS-rukBk1RC7E~YGZe66NG0XyVcAR5rINP2owdVnGA__',
            points: [
                {
                    id: 0,
                    text: 'Avijo Expert is more than just an app—it’s a comprehensive tool designed to support your growth as a healthcare professional. Whether you’re looking to streamline your practice, increase your patient base, or contribute to a broader healthcare community, Avijo Expert has the features and functionality you need.'
                }
            ]
        },
    ]

    return (
        <>
            {data.map((item, index) => (
                index % 2 === 0 ? (index % 2 === 0 &&
                    <div key={index} className="flex flex-col md:flex-row pb-4 border-1 rounded-[5px] m-4 justify-between items-center">
                        <div className="flex flex-col p-2 w-full md:w-1/2 h-full">
                            <div className="flex-grow">
                                <h3 className="text-[36px] font-gilroy font-semiBold">{item.heading}</h3>
                                <ul className="list-disc pl-0">
                                    {item.points.map((point, idx) => (
                                        <div key={idx} className="text-[20px] text-[#424242] font-gilroy font-medium mt-2">
                                            <p className="expert-paragraph">{point.text}
                                                {/* <Link to="/doctor/login" className="text-decoration-none ps-2">
                                                    Learn more..
                                                </Link> */}
                                            </p>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="p-4 pb-0 w-full md:w-1/2">
                            <img
                                src={item.image}
                                className="h-[310px] w-[500px] rounded-2 md:ml-10"
                            />
                        </div>
                    </div>
                ) : (
                    <div key={index} className="flex flex-col md:flex-row pb-4 border-1 rounded-[5px] m-4 justify-between items-center">
                        <div className="p-4 pb-0 w-full md:w-1/2">
                            <img
                                src={item.image}
                                className="h-[310px] w-[500px] rounded-2"
                            />
                        </div>
                        <div className="flex flex-col p-2 w-full md:w-1/2 h-full">
                            <div className="flex-grow">
                                <h3 className="text-[36px] font-gilroy font-semiBold">{item.heading}</h3>
                                <ul className="list-disc pl-0">
                                    {item.points.map((point, idx) => (
                                        <div key={idx} className="text-[20px] text-[#424242] font-gilroy font-medium mt-2">
                                            <p className="expert-paragraph">{point.text}
                                                {/* <Link to="/doctor/login" className="text-decoration-none ps-2">
                                                    Learn more..
                                                </Link> */}
                                            </p>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            ))}
        </>
    )
}


export default AvijoExpert;