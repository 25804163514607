import React from "react";
import SecondNavbar from "../components/Users/navbar/SecondNavbar";
import SearchSection from "../components/Users/Ui/SearchSection";
import { Link } from "react-router-dom";
import Footer from "../components/Home/footer/Footer";

const About = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("myPath", window.location.pathname);
  }, []);


  return (
    <>
      {" "}
      <div className="bg-color-ab">
        {/* <SecondNavbar /> */}
        <div className="bg-white mt-5">
          {" "}
          <div className="container about mt-4  px-4 py-4 rounded-lg">
            <div>
              <h1 className=" text-center">Our Mission</h1>
              <p className=" text-center">
                We are on mission to build End-to-End Healthcare Ecosystem
                through empowering Healthcare Facilities and Professionals by
                leveraging Technology to make healthcare affordable, accessible,
                Safe & convenient for billions of people with Ayushman Bharat
                Digital Mission(ABDM) by acting as Health information exchange
                and consent manager in ABDM.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white">
          {" "}
          <div className="container mt-4  px-4 py-5 rounded-lg about-second">
            <h1>avijo is guided by fours core principles</h1>
            <ul className="d-flex flex-column flex-sm-row gap-5 mt-4 list-none">
              <div>
                <div className="d-flex gap-3 align-items-center">
                  {" "}
                  <svg
                    width="10"
                    height="14"
                    viewBox="0 0 10 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.9026 5.96536H7.86926L9.06927 6.86537C9.08997 6.88089 9.10677 6.90102 9.11834 6.92416L9.36927 6.7987L9.11834 6.92416C9.12992 6.94731 9.13594 6.97283 9.13594 6.9987C9.13594 7.02457 9.12992 7.05009 9.11834 7.07323L9.56556 7.29684L9.11834 7.07324C9.10677 7.09638 9.08997 7.11651 9.06927 7.13203L9.36927 7.53203L9.06927 7.13203L1.07669 13.1265C1.05356 13.1419 1.02692 13.1513 0.999195 13.1538C0.972471 13.1562 0.945597 13.1521 0.920846 13.1419C0.896325 13.1281 0.875659 13.1083 0.860823 13.0843L0.436247 13.3467L0.860822 13.0843C0.844731 13.0583 0.836123 13.0283 0.835938 12.9977V0.998698C0.835938 0.967746 0.844557 0.937406 0.860829 0.911076C0.877102 0.884747 0.900384 0.863469 0.928069 0.849627C0.955753 0.835785 0.986745 0.829925 1.01757 0.832705C1.0484 0.835485 1.07784 0.846794 1.1026 0.865365L7.9026 5.96536Z"
                      fill="#0095D9"
                      stroke="#C9C9C9"
                    />
                  </svg>{" "}
                  <li>
                    Customers obsession for there health care rather than
                    competition focus
                  </li>
                </div>
                <div className="d-flex gap-3 align-items-center">
                  <svg
                    width="10"
                    height="14"
                    viewBox="0 0 10 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.9026 5.96536H7.86926L9.06927 6.86537C9.08997 6.88089 9.10677 6.90102 9.11834 6.92416L9.36927 6.7987L9.11834 6.92416C9.12992 6.94731 9.13594 6.97283 9.13594 6.9987C9.13594 7.02457 9.12992 7.05009 9.11834 7.07323L9.56556 7.29684L9.11834 7.07324C9.10677 7.09638 9.08997 7.11651 9.06927 7.13203L9.36927 7.53203L9.06927 7.13203L1.07669 13.1265C1.05356 13.1419 1.02692 13.1513 0.999195 13.1538C0.972471 13.1562 0.945597 13.1521 0.920846 13.1419C0.896325 13.1281 0.875659 13.1083 0.860823 13.0843L0.436247 13.3467L0.860822 13.0843C0.844731 13.0583 0.836123 13.0283 0.835938 12.9977V0.998698C0.835938 0.967746 0.844557 0.937406 0.860829 0.911076C0.877102 0.884747 0.900384 0.863469 0.928069 0.849627C0.955753 0.835785 0.986745 0.829925 1.01757 0.832705C1.0484 0.835485 1.07784 0.846794 1.1026 0.865365L7.9026 5.96536Z"
                      fill="#0095D9"
                      stroke="#C9C9C9"
                    />
                  </svg>{" "}
                  <li>Passion for Innovations</li>{" "}
                </div>
                <div className="d-flex gap-3 align-items-center">
                  <svg
                    width="10"
                    height="14"
                    viewBox="0 0 10 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.9026 5.96536H7.86926L9.06927 6.86537C9.08997 6.88089 9.10677 6.90102 9.11834 6.92416L9.36927 6.7987L9.11834 6.92416C9.12992 6.94731 9.13594 6.97283 9.13594 6.9987C9.13594 7.02457 9.12992 7.05009 9.11834 7.07323L9.56556 7.29684L9.11834 7.07324C9.10677 7.09638 9.08997 7.11651 9.06927 7.13203L9.36927 7.53203L9.06927 7.13203L1.07669 13.1265C1.05356 13.1419 1.02692 13.1513 0.999195 13.1538C0.972471 13.1562 0.945597 13.1521 0.920846 13.1419C0.896325 13.1281 0.875659 13.1083 0.860823 13.0843L0.436247 13.3467L0.860822 13.0843C0.844731 13.0583 0.836123 13.0283 0.835938 12.9977V0.998698C0.835938 0.967746 0.844557 0.937406 0.860829 0.911076C0.877102 0.884747 0.900384 0.863469 0.928069 0.849627C0.955753 0.835785 0.986745 0.829925 1.01757 0.832705C1.0484 0.835485 1.07784 0.846794 1.1026 0.865365L7.9026 5.96536Z"
                      fill="#0095D9"
                      stroke="#C9C9C9"
                    />
                  </svg>{" "}
                  <li>Commitment for operational Excellence</li>
                </div>
                <div className="d-flex gap-3 align-items-center">
                  <svg
                    width="10"
                    height="14"
                    viewBox="0 0 10 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.9026 5.96536H7.86926L9.06927 6.86537C9.08997 6.88089 9.10677 6.90102 9.11834 6.92416L9.36927 6.7987L9.11834 6.92416C9.12992 6.94731 9.13594 6.97283 9.13594 6.9987C9.13594 7.02457 9.12992 7.05009 9.11834 7.07323L9.56556 7.29684L9.11834 7.07324C9.10677 7.09638 9.08997 7.11651 9.06927 7.13203L9.36927 7.53203L9.06927 7.13203L1.07669 13.1265C1.05356 13.1419 1.02692 13.1513 0.999195 13.1538C0.972471 13.1562 0.945597 13.1521 0.920846 13.1419C0.896325 13.1281 0.875659 13.1083 0.860823 13.0843L0.436247 13.3467L0.860822 13.0843C0.844731 13.0583 0.836123 13.0283 0.835938 12.9977V0.998698C0.835938 0.967746 0.844557 0.937406 0.860829 0.911076C0.877102 0.884747 0.900384 0.863469 0.928069 0.849627C0.955753 0.835785 0.986745 0.829925 1.01757 0.832705C1.0484 0.835485 1.07784 0.846794 1.1026 0.865365L7.9026 5.96536Z"
                      fill="#0095D9"
                      stroke="#C9C9C9"
                    />
                  </svg>{" "}
                  <li>Long-term visionary thinking</li>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div className="bg-white about-3">
          <div className="container mt-5 px-4 py-5">
            <div className="row">
              <div className="col-md-6">
                <h2>Transformation from Sick Care to Healthcare</h2>
                <p>
                  The shift from sick care to healthcare marks a crucial
                  transformation in our approach to wellness. Traditional sick
                  care focuses on treating illnesses, often reactively
                  addressing symptoms. In contrast, healthcare emphasizes
                  proactive and preventive measures, aiming to maintain and
                  improve overall health. This involves regular screenings,
                  lifestyle modifications, and holistic care, empowering
                  individuals to take charge of their health. By prioritizing
                  early intervention and continuous wellness, we can reduce the
                  incidence of chronic diseases, enhance quality of life, and
                  create a healthier society. This paradigm shift underscores
                  the importance of a proactive, patient-centric approach to
                  well-being.
                </p>
              </div>
              <div className="col-md-6">
                <img
                  src={require("../Assets/image/doctor about.png")}
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white blog-about">
          <div className="container mt-5 py-5">
            <h2 className="mb-5 heading-1">avijo offerings</h2>

            <ul className="d-flex flex-column flex-sm-row gap-5 mt-4">
              <div>
                <li>Health tracking and record storing</li>
                <li>Docare(Your Health Community)</li>
                <li>
                  Online appointment booking at over leading hospitals and
                  clinics with doctors who use Avijo Export
                </li>
              </div>
              <div>
                <li>
                  Online consultation with trusted doctors across 20+
                  specialities
                </li>
                <li>Medicine delivery by a network of verified pharmacies</li>
                <li>
                  Diagnostic Tests through Associate Labs to get samples
                  collected from the comfort and safety of one’s home
                </li>
              </div>
            </ul>
            <div className="row">
              <div className="col-md-4">
                <img
                  src={require("../Assets/image/avijoAlpha.png")}
                  className="mb-3"
                ></img>
                <p>
                  Avijo Alpha is a versatile cloud-based software solution
                  designed for a wide range of health facilities, including
                  hospitals, clinics, pharmacies, and diagnostic labs. It
                  streamlines and enhances the management of operations, patient
                  records, appointment scheduling, and administrative tasks
                  across various healthcare settings. With intuitive interfaces
                  and robust data security, Avijo Alpha ensures efficient
                  handling of patient information and improves the overall
                  workflow within these facilities. By integrating seamlessly
                  with other healthcare systems, Avijo Alpha optimizes resource
                  utilization and provides a superior healthcare experience for
                  both providers and patients.
                </p>
              </div>
              <div className="col-md-4">
                <img
                  src={require("../Assets/image/avijoExpert.png")}
                  className="mb-3"
                ></img>
                <p>
                  Avijo Expert is a comprehensive cloud-based platform tailored
                  for health professionals, including doctors, specialists, and
                  medical practitioners. It provides tools for managing patient
                  consultations, medical records, and treatment plans. The
                  software includes features such as telemedicine support,
                  e-prescriptions, and patient follow-ups, enabling healthcare
                  professionals to deliver high-quality care remotely and in
                  person. Avijo Expert is designed to facilitate better
                  communication between healthcare providers and patients,
                  enhancing the efficiency and effectiveness of medical
                  services.
                </p>
              </div>
              <div className="col-md-4">
                <img
                  src={require("../Assets/image/avijoHPP.png")}
                  className="mb-3"
                ></img>
                <p>
                  Avijo HPP is a web application developed for Health Product
                  Provider (HPP) companies, including pharmaceutical firms,
                  medical device manufacturers, and suppliers. This platform
                  allows HPP companies to manage their product listings, track
                  inventory, process orders, and interact with healthcare
                  facilities and professionals. Avijo HPP aims to streamline the
                  supply chain in the healthcare industry, ensuring timely
                  delivery of essential medical products and fostering a more
                  connected and responsive healthcare ecosystem.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white blog-doctor">
          <div className="container mt-5 py-5 ">
            <h2 className="mb-4 text-center">Our Approach to Healthcare</h2>
            <h5 className="text-center mb-4">
              Avijo - Connecting, Trusting, and Changing Healthcare for a Better
              Future
            </h5>
            <div className="row gap-5 mt-4">
              <div className="col-md-30 rounded">
                <div>
                  <img
                    src={require("../Assets/image/download (4) 1.png")}
                    alt=""
                    srcset=""
                  />
                </div>
                <div>
                  <h3 className="my-3 ms-3">Connect</h3>
                  <p>
                    {" "}
                    We bridge the gap between Health Service and Product
                    Providers (HSPP) and Health Information Users (HIU) through
                    seamless integration and efficient communication. Our
                    platform ensures easy access to healthcare services and
                    products, fostering a collaborative environment.
                  </p>
                </div>
              </div>
              <div className="col-md-30 rounded">
                <div>
                  <img
                    src={require("../Assets/image/download (4) 1 (1).png")}
                    alt=""
                    srcset=""
                  />
                </div>
                <div>
                  <h3 className="my-3 ms-3">Trust</h3>
                  <p>
                    Building trust is at the core of our mission. We prioritize
                    data security and transparency, ensuring that users' health
                    information is handled with the utmost care. Our compliance
                    with the Ayushman Bharat Digital Mission (UHI) standards
                    reinforces our commitment to reliability and integrity.
                  </p>
                </div>
              </div>{" "}
              <div className="col-md-30 rounded">
                <div>
                  <img
                    src={require("../Assets/image/download (4) 1 (2).png")}
                    alt=""
                    srcset=""
                  />
                </div>
                <div>
                  <h3 className="my-3 ms-3">Change</h3>
                  <p>
                    We drive positive change by shifting from reactive sick care
                    to proactive healthcare. By emphasizing preventive measures
                    and continuous wellness, we empower individuals to take
                    control of their health, leading to improved outcomes and a
                    healthier society.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
