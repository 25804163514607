import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PharmacyLogin from "../../components/Pharmacy/PharmacyLogin/PharmacyLogin";
import PharmacyOtpVerify from "../../components/Pharmacy/PharmacyOtpVerify/PharmacyOtpVerify";
import PharmacyResetPassoword from "../../components/Pharmacy/PharmacyResetPassoword/PharmacyResetPassoword";
const ResetPassword = () => {
  React.useEffect(() => {
    localStorage.setItem("myPath", window.location.pathname);
  }, []);

  return (
    <div>
      <div className="container">
        <Tabs
          defaultActiveKey="Register"
          id="fill-tab-example"
          className="mb-3"
          fill
        >
          <Tab eventKey="Login" title="Login">
            {/* <PharmacyLogin /> */}
          </Tab>
          <Tab eventKey="Register" title="Register">
            <PharmacyResetPassoword />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default ResetPassword;
