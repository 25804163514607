import React from "react";
import "./HealthHPP.css";
import { Link } from "react-router-dom";
function HealthHPP() {
  return (
    <>
      <section class="text-gray-600 body-font relative w-[100%]">
        <div className="container py-24 mx-auto w-[100%]">
          <div className="row justify-between w-[100%]">
            {" "}
            <div className="col-md-6">
            <h3 className="heading text-start font-[Gilroy-SemiBold]">avijo</h3>
            <p className="userApp-para">Your Health Guardian</p>
              <div className="health-left-sec">
                {/* <h3 className="left-heading"> User App </h3> */}
                <p className="userApp-para">
                  It helps the users in locating the best specialist around
                  them, also can order medicine, find a hospital and Lab, etc.
                </p>
                <div className="health-para">
                  <p> ✔ Find nearby doctors, hospitals. & Lab w/map.</p>
                  <p> ✔ Book appointments, video consultation with Doctor .</p>
                  <p> ✔ Multi-vendor medicine and health product ordering.</p>
                  <p>
                    {" "}
                    ✔ Book appointments home sample collection for Lab test.
                  </p>
                  <p> ✔ Health Emergency services.</p>
                  <p> ✔ Health Tracking .</p>
                </div>
                <Link
                  to="/user/login"
                  className="health-btn text-decoration-none pt-3 ps-3"
                >
                  Learn More / Register as Health Id
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <iframe
                width="560"
                height="450"
                src="https://www.youtube.com/embed/5ZvLR1DcNBo?si=fvxaNiH408NPVpzh"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HealthHPP;
