import React from "react";
import { Link } from "react-router-dom";

const ReturnRefundAndCancellationPolicy = () => {
  return (
    <div>
      {" "}
      <div>
        <div className="container mt-5">
          <div className="row team-row">
            <div className="col-md-4">
              <h4 className="mb-4">OUR POLICIES</h4>
              <ul>
                <li>
                  <Link to="/Terms-and-conditions">Terms and conditions</Link>
                </li>
                <li>
                  <Link to="/Privacy-policy">Privacy policy</Link>
                </li>
                <li>
                  <Link to="/Fees-and-Payments-policy">
                    Fees and Payments policy
                  </Link>
                </li>
                <li>
                  <Link to="/Shipping-and-Delivery-policy">
                    Shipping and Delivery policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Return-Refund-And-Cancellation-Policy"
                    className="active-side"
                  >
                    Return, Refund And Cancellation Policy
                  </Link>
                </li>
                <li>
                  <Link to="/Editorial-policy">Editorial policy</Link>
                </li>
              </ul>
              {/* <span className="line"></span> */}
            </div>
            <div className="col-md-8">
              <h4>RETURN, REFUND AND CANCELLATION POLICY</h4>

              <p>
                a company incorporated under the laws of India, having its
                registered office at registered address at 50 SANJAY COLONY,
                WARD NO -07, DANDAPURA, Vidisha, Vidisha, Vidisha- 464001,
                Madhya Pradesh (“avijo, we, us”), manages and operates
                www.avijo.in and a mobile application under the brand name avijo
                (collectively referred to as the “ Website”) where users can
                place orders to purchase the Products and/or Services offered by
                us listed/available on the Website (“User” or “Users” or “you”).
              </p>

              <h4>
                <strong>Policy Introduction</strong>
              </h4>
              <p>
                We aim to provide the best User experience to our Users
                beginning from placing order and/or booking an appointment to
                initiating a return. While transacting on the Website, you can
                expect a hassle-free experience in returning the Product and
                cancelling the Services that you have ordered/booked and can
                rely on us as your preferred shopping destination or Service
                location.
              </p>

              <h4>
                <strong>APPLICABILITY OF POLICY</strong>
              </h4>
              <p>
                A) By agreeing to use the Website and/or placing a request for
                purchase of Product(s) and/or Services on the Website, you agree
                to be bound by the terms contained in this Policy without
                modification. If you do not agree to the terms contained in this
                Policy, you are advised not to transact on the Website. B)
                Please note that we may from time to time change the terms of
                the Policy that govern your return, refund and cancellation of
                an order for Products on the Website. Every time you wish to use
                the Website, please check the Policy to ensure you understand
                the terms and conditions that apply at that time.
              </p>

              <h4>
                <strong>APPLICABILITY OF POLICY</strong>
              </h4>
              <p>
                A) By agreeing to use the Website and/or placing a request for
                purchase of Product(s) and/or Services on the Website, you agree
                to be bound by the terms contained in this Policy without
                modification. If you do not agree to the terms contained in this
                Policy, you are advised not to transact on the Website. B)
                Please note that we may from time to time change the terms of
                the Policy that govern your return, refund and cancellation of
                an order for Products on the Website. Every time you wish to use
                the Website, please check the Policy to ensure you understand
                the terms and conditions that apply at that time. TERMS FOR
                CANCELLATION, RETURN AND REFUND OF ORDERS
              </p>
              <p>
                <strong>(A) RETURN POLICY:</strong>
              </p>
              <p>
                i. Return Policy: We do our best to ensure that the products you
                order are delivered according to your specifications. However,
                should you receive an (a) incomplete order, (b) expired product,
                (c) damaged product, (d) incorrect product, (e) doctor changes
                the prescription (f) death of a patient, please notify avijo
                User Support immediately within 7 days of receiving the
                products, to ensure prompt resolution. Please note that avijo
                will not accept liability for such delivery issues if you fail
                to notify us within 7 days of receipt. User cannot return
                non-refundable Product; it is User’s responsibility to check
                whether the Product falls under returnable/non-returnable
                category before placing an order.
              </p>
              <p>
                ii. Return Policy Exceptions: Please note that we are unable to
                offer replacements or exchanges for the following product
                categories: Injections, Health Monitor & Equipment and Ortho
                Support. Also, avijo reserves the right to refuse returns (or
                refunds) for certain products, as marked in the respective
                product pages as "Note: This item cannot be returned for a
                refund or exchange".
              </p>
              <h4>(B) REFUND POLICY:</h4>
              <p>
                i. Refund Policy: At avijo, we do our best to ensure that you
                are completely satisfied with our products. And we are happy to
                issue a full refund based on the conditions listed below:
              </p>
              <h4>Refund Policy Continued</h4>
              <p>
                <strong>a. Reasons for Refund:</strong>
                <br />
                i. you received a defective item
                <br />
                ii. the ordered item(s) is lost or damaged during transit.
                <br />
                iii. you have received the product that has crossed the expiry
                date
                <br />
                It is hereby clarified that in-case return is requested for
                reasons attributable to the User, We may at our discretion
                accept such request subject to levy of a ‘Restocking Fee’ to the
                User. It may be noted that the amount shall be refunded to you
                after adjusting the Re-stocking Fee. We reserve the right to
                revise the said Restocking Fee from time to time without any
                advance notice to the User. It is further clarified We may
                accept return request only for those products which are
                original, unused condition, has not been damaged or mistreated,
                has no signs of use, scratches, wear or tear, and is in
                condition for resale as new.
                <br />
                <strong>iii. Refund Process:</strong>
                <br />
                Mode of refund may vary depending on circumstances. If the mode
                of refund is by Credit/Debit Card or Net Banking, please allow 7
                to 10 working days for the credit to appear in your account.
                While we regret any inconvenience caused by this time frame, it
                is the bank's policy that delays the refund timing, and we have
                no control over that. If the mode of refund is by wallet, credit
                should be available within 24 hours.
                <br />
                For Cash on Delivery orders, refunds will be processed to the
                user's wallet or bank account upon request via email to
                cs@avijo.in or by calling +91 07067635032. If refunded to the
                bank account, the user must provide the necessary bank account
                details.
                <br />
                <strong>iv. Wallet Refunds:</strong>
                <br />
                If the refund is credited to the wallet, any unused amount in
                the wallet will be transferred to the user's bank account upon
                account deactivation.
                <br />
                <strong>v. Direct Bank Refunds:</strong>
                <br />
                avijo shall directly refund to the User’s bank account in
                situations such as when excessive price has been
                charged/collected from the User or any other cases where avijo
                has to refund the amount to the User which belongs to the User.
              </p>

              <h4>Requesting a Refund</h4>
              <p>
                To request a refund, simply email us your order details,
                including the reason why you're requesting a refund. We take
                User feedback very seriously and use it to constantly improve
                our quality of service.
              </p>

              <h4>Cancellation</h4>
              <p>
                <strong>i. Product Cancellation:</strong>
                <br />
                User may cancel the order of a product before it is out for
                delivery. On the receipt of order cancellation request, your
                order will be cancelled with immediate effect.
                <br />
                <strong>ii. Service Cancellation:</strong>
                <br />
                The Website shall only connect the User with the
                Doctor/registered medical practitioners of the service booked.
                Once the appointment has been made and a confirmation has been
                given to the User, the Company shall inform the
                Doctor/registered medical practitioners who shall render the
                consult at the agreed time, location and mode provided at the
                time of booking the service. We are in no way liable for any
                deficiency of services if any arises including but not limited
                to cancellation of order due to unavailability of the
                Doctor/registered medical practitioners.
                <br />
                Once an appointment has been made and it is confirmed at our
                end, no amount shall be refunded, in the event of a
                cancellation. However, in certain circumstances, the Company at
                its discretion shall permit a refund of the full or partial
                amount if the Doctor/registered medical practitioners becomes
                unavailable or if an alternative Doctor/registered medical
                practitioners is arranged to consult. The decision of the
                Company shall be final in such cases.
              </p>
              <h4>User Complaints</h4>
              <p>
                If you have any queries, do call our help desk at +91
                07067635032, email us at{" "}
                <a href="mailto:cs@avijo.in">cs@avijo.in</a>, or contact our
                User support executives through online chat. We're here for you!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnRefundAndCancellationPolicy;
