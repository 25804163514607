import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ExpertNav from "../components/Navbar/ExpertNav";
import Footer from "../components/Home/footer/Footer";

const HppHome = () => {

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[]);

    return (
        <div className="App w-[100%]">
            <ExpertNav />
            <Header />
            <Carousel />
            <Section1 />
            <Section2 />
            <Footer />
        </div>
    )
}


const Header = () => {
    const navigate = useNavigate();
    return (
        <header className="hero bg-gradient-to-r from-[#F6F6F6] to-[#C8C8C8]">
            <div class="flex flex-col md:flex-row justify-between p-4">
                <div className="flex flex-col p-2 w-full md:w-1/2 h-full">
                    <div className="flex-grow">
                        <span className="text-[16px] font-gilroy font-[600] text-[#535353]">
                            AI-POWERED SUPPLY CHAIN MANAGEMENT PLATFORM
                            AUTOMATE & OPTIMIZE END-TO-END SUPPLY CHAIN OPERATIONS
                        </span>
                        <div className="flex flex-row items-end">
                            <h1 className="font-semiBold text-[45px] text-[#535353] font-[Gilroy-SemiBold]">avijo</h1><h6 className="text-xl text-[#FFCA38] italic">HPP</h6>
                        </div>
                        <span className="text-xl text-[#535353] mb-5 font-[500]">
                            Kickstart your digitalisation journey today!
                        </span>
                    </div>
                    <span className="text-[16px] font-gilroy font-[400] text-[#535353] mt-2">
                        Struggling with inefficient outdated manual processes, error-prone data management, and lack real-time visibility.
                    </span>
                    <button onClick={() => navigate("/hpp/login")} className="bg-[#0097DB] h-10 w-40 rounded-lg mt-4">
                        <span className="text-sm">Get Started</span>
                    </button>
                </div>
                <div className="p-4 w-full md:w-1/2">
                    <img src='https://s3-alpha-sig.figma.com/img/0cbe/98ab/c2861e595da7ab1cc69f8cbad9188dc0?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=psLJ5ODWqF7ityUqp5olmK2qFEIpGjfa~TF6-w0Um-K8Fa1oPmaE2CwfR4Etlmt-yINXD5NgWdDZZPYxkXF8vbdU6jRF5-D2Ktl6jejyBlBOe7Y0j6~wCssE5aRb-qwENkONt1m60~zd6L7Cbo6krliecc2E7iCxGJDJ4PyEsO~8H1c3auRl0qJmy-hs9yGh15HWTBvB5NtlTmg7XjylVluU4oVYCBbsENUY50rGlEp26~at6c6n0iFhVcHPLGh6AjkEJb2iByNwnFtV7Ep63KrMd7mqTeTG1qpVeni6iOStyOYbqzfsomnWrKO5n9tIFBnLuUj0KdACzXrTR9apTA__' alt="header" className="h-60 w-90 md:ml-10" />
                </div>
            </div>
        </header>
    );
}

const Carousel = () => {
    return (
        <div id="carouselExample" class="carousel slide">
            <div class="carousel-inner">
                <div class="carousel-item active flex flex-col items-center bg-[#FFF0F0]">
                    <h2 className="self-center text-center ml-[20%] w-[60%] pt-5 text-4xl">Efficiency, Visibility, Control.</h2>
                    <div className="flex flex-row items-center justify-center mt-2 w-[90%] ml-10">
                        <span className="text-black font-semibold text-[32px]">Optimise your operations and elevate your success with avijo HPP. </span>
                    </div>
                    <div class="flex flex-col md:flex-row justify-between p-4 items-center">
                        <div className="flex flex-col p-2 w-full md:w-[60%] h-full">
                            <div className="flex-grow">
                                <h1 className="text-2xl text-black ml-4">Comprehensive Patient Management</h1>
                                <h1 className="text-lg text-black ml-4">Real-Time Visibility</h1>
                                <div className="flex flex-row items-start mt-2">
                                    <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                                    <span className="text-black text-sm font-[400]">Gain transparency & control of your operations with end-to-end visibility of upply chain, from order to delivery.</span>
                                </div>
                                <h1 className="text-lg text-black ml-4">Enhanced Business Agility & Efficiency</h1>
                                <div className="flex flex-row items-start mt-2">
                                    <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                                    <span className="text-black text-sm font-[400]">Provides a unified dashboard for real-time monitoring of healthcare operations</span>
                                </div>
                                <h1 className="text-lg text-black ml-4">Seamless Collaboration on One Platform</h1>
                                <div className="flex flex-row items-start mt-2">
                                    <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                                    <span className="text-black text-sm font-[400]">Ensures compliance with national and international healthcare regulations</span>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <button className="bg-[#FFF0F0] p-2 w-1/8 rounded-lg mt-4 border-1 border-[#0097DB] mr-4">
                                    <span className="text-sm text-[#0097DB]">Learn more</span>
                                </button>
                                <button className="bg-[#0097DB] p-2 w-40 rounded-lg mt-4">
                                    <span className="text-sm text-white"> Register for Demo</span>
                                </button>
                            </div>
                        </div>
                        <div className="p-4 w-[500px] h-[260px] md:w-[40%]">
                            <img src='https://s3-alpha-sig.figma.com/img/93e8/2579/bc268683ab32d11186aa2ebc29108500?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=aYwY2jc52sh7fsPWU3wIs-HQAHt9JYhVdayRvHUd0NPa2mF5vmdYNBSeTnrDGUHSl1LWbd64REZix~kqiqlOew0pJjBF3EMGhqrGRVzRdC0gATZq5iCMzhinHl5zgNZPA37-uG6JT97gxR60lPqYhSzIWDfFwGUCsOm4jg2WAx7ymsasL6Z7vVgO7dq1srMADNDn6xS16ErNTJFvlgrZT7Q3Pl9Uj8om1lbMkrnpLSoLFrZFh7U~ROo4YoWzCmS8JQIEJlRURiXqPuceNpuP~eprUmNdC5W8~p9b5g9SdQYzydm9vYb~dQAnvln0tj7hZ3wH3jwhGTAaIR2bAmxnHA__' alt="header" className="h-[240px] w-[100%]" />
                        </div>
                    </div>
                </div>
                <div class="carousel-item flex flex-col items-center bg-[#FAEFD4]">
                    <h2 className="self-center text-center ml-[20%] w-[60%] pt-5 text-4xl">SUPPLY CHAIN MANAGEMENT SOLUTIONS</h2>
                    <div className="flex flex-row items-center justify-center mt-2 w-[90%] ml-10">
                        <span className="text-black font-semibold text-center text-[32px]">Optimize your operations and grow your business with our comprehensive solutions.</span>
                    </div>
                    <div class="flex flex-col md:flex-row justify-between p-4 items-center">
                        <div className="flex flex-col p-2 w-full md:w-[60%] h-full">
                            <div className="flex-grow">
                                <h1 className="text-2xl text-black ml-4">AI-Powered Operations</h1>
                                <div className="flex flex-row items-start mt-2">
                                    <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                                    <span className="text-black text-[20px] font-gilroy font-medium">Leveraging advanced technology and AI capabilities, we offer seamless connection, greater visibility, and meet operational needs in the Industry 4.0 era. Our user-friendly modular solutions cater to client needs, offering a dynamic, scalable, and flexible platform.</span>
                                </div>
                                <div className="flex flex-row items-start mt-2">
                                    <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                                    <span className="text-black text-[20px] font-gilroy font-medium">With deep domain expertise and extensive knowledge, as a global trading powerhouse in the automotive industry, avijo constantly progresses and builds capabilities to provide a one-stop supply chain management (SCM) solution.</span>
                                </div>
                            </div>
                            <button className="bg-[#0097DB] p-2 w-[100px] rounded-lg mt-4">
                                <span className="text-sm text-white text-center">Learn More</span>
                            </button>
                        </div>
                        <div className="p-4 w-[350] h-[350px] md:w-50">
                            <img src='https://s3-alpha-sig.figma.com/img/e612/975c/eb894bd953f066e804a816ce902ec273?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=qRGir1~qGKAahV1xBm17a2ma3SzjA2uRnPRANmDkbuGIW~VUYMMItCYiUWX5So3ACl-2XbFRb-NlJhYNsy31vpCSpsmOArrOKqEoYIXvFyU29hmMt0kWX4hU6ryYmdk914qeDZZufkWoNok84FCQxpZiUfDuyRjZCvs~VRBV-AMr1zU0mU7ImoHe36u6vis3zsyRUzRbzDZvnbDWD1IVb54BkBBCY2KjkUQyXfkVuUSb27GHbgtDsCBxWcEQUPm5ss54IfJomnHKbMd122dZwq4LeZdzDi62KtfRJijb2s3EDVtsdEELhOQj6ywqrwBhrOzBdmBhv~A0QzRdHSc3LQ__' alt="header" className="h-full w-full" />
                        </div>
                    </div>
                </div>
            </div>
            <button
                className="absolute top-1/2 left-0 transform -translate-y-1/2 p-2"
                data-bs-target="#carouselExample"
                 data-bs-slide="prev"
                style={{ backgroundColor: 'transparent' }}
            >
                <img src={require('../Assets/image/left.png')} alt="Previous" className="w-8 h-8" />
            </button>
            <button
                className="absolute top-1/2 right-0 transform -translate-y-1/2 p-2"
                data-bs-target="#carouselExample"
                data-bs-slide="next"
                style={{ backgroundColor: 'transparent' }}
            >
                <img src={require('../Assets/image/right.png')} alt="Next" className="w-8 h-8" />
            </button>
        </div>
    )
}

const Section1 = () => {
    return (
        <section className="w-[100%] flex flex-col items-center border-1 pb-5">
            <img src="https://s3-alpha-sig.figma.com/img/33df/b09e/9697362488f1500d7ef6147437d5ae27?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ilu2dzHgoOFCIAVk8d5i509epysYTelmcVUzhhinD3P1hRsn1wnTSK0Fp3Qx~PQTjJarz-SN613lwzndII2ZFI6ejLP72ywfQJrUZjby0tvvY3lNkdvrfzsR-pKVxLlD-Hl6GJD8RllOQ-sTuQz9sF3g7srM~WFjh~kR3hxcx8VglRY6ffEQL9ABaSWSjcXBDLbLwmPrkQS6VojbKNryf67V-ZqPPR4a1qKdw0EVVEcZiquRCeRKBprdfE17aR~hpU11Mp4nOWbGMU0F89EWN52HwmDpYl3QjFXpIQGS04ZwHgM45UMIWmhoPMRrVwsSZXCumEKUQqb37Z07drzApA__" className="w-[90%] h-[272px] mt-5" alt='img' />
            <span className="text-lg text-[27px] w-[90%] font-[500] mt-3">Integrate your data sources (internal & external) on avijo HPP to gain insights on your production and stimulate your order process to better understand your production capacity.</span>
            <div className="flex flex-col md:flex-row items-start justify-between mt-5 w-[90%] ml-10">
                <div className="flex-grow">
                    <h1 className="text-2xl text-black ml-4">Shipment Management</h1>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]">Intelligent Cargo Tracking</span>
                    </div>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]">Real-time ETA predictions</span>
                    </div>
                </div>
                <div className="flex-grow">
                    <h1 className="text-2xl text-black ml-4">Inventory Management</h1>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]"> Demand/Forecast Planning</span>
                    </div>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]">Centralized Inventory Monitoring</span>
                    </div>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]"> Inventory Simulation</span>
                    </div>
                    <button className="bg-[#0097DB] h-8 w-28 rounded-lg mt-4">
                        <span className="text-sm text-white">Try it</span>
                    </button>
                </div>
                <div className="flex-grow">
                    <h1 className="text-2xl text-black ml-4">Order, Delivery & Invoice Management</h1>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]">Order & Delivery Process Management</span>
                    </div>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]"> Supplier Management</span>
                    </div>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]">SKU/Parts Management & Visualization</span>
                    </div>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]"> Automated Invoice Generation</span>
                    </div>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" />
                        <span className="text-black text-sm font-[400]">Online Communication</span>
                    </div>
                    <button className="bg-[#0097DB] h-8 w-40 rounded-lg mt-4">
                        <span className="text-sm text-white">Request for Demo</span>
                    </button>
                </div>
            </div>
        </section>
    )
}

const Section2 = () => {
    return (
        <div className="flex flex-col w-[100%] items-center border-1 pb-5">
            <h2 className="self-center text-center w-[60%] pt-5 text-4xl">Simplify, Integrate, Optimise.</h2>
            <div className="flex flex-row items-center justify-center mt-2 w-[90%]" >
                <span className="text-black font-semibold text-[20px]">Unlock the benefits of an interconnected and streamlined supply chain with avijo HPP!</span>
            </div>
            <div className="flex flex-col md:flex-row items-start justify-between mt-5 w-[90%] ml-10">
                <div className="flex flex-col w-full md:w-1/2">
                    <h1 className="text-2xl text-black">Distribution Centre</h1>
                    <span className="text-black text-sm font-[400] pr-2">Gain clear visualisation & effectively manage all pending orders, billings and shipment statuses, with real-time data updates, on a single platform.</span>
                    <span className="text-black text-sm font-[400]">Real-Time Incoming Cargo Updates</span>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow2.png')} className="w-[20px] h-[20px] mr-2" />
                        <span className="text-black text-sm font-[400]">Intelligent Cargo Tracking</span>
                    </div>
                </div>
                <div className="flex flex-col w-full md:w-1/2">
                    <h1 className="text-2xl text-black">Business Unit</h1>
                    <span className="text-black text-sm font-[400] pr-2">Enhance the interoperability of your systems and stay in sync with real-time integrated updates.</span>
                    <span className="text-black text-sm font-[400]">Manage internal resources & operations</span>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow2.png')} className="w-[20px] h-[20px] mr-2" />
                        <span className="text-black text-sm font-[400]">Enterprise Resource Systems</span>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-start justify-between mt-5 w-[90%] ml-10">
                <div className="flex flex-col w-full md:w-1/2">
                    <h1 className="text-2xl text-black">Customer</h1>
                    <span className="text-black text-sm font-[400] pr-2">Mitigate communication breakdown and save the hassle of multi-logins & manual information input by collaborating & integrating with all your suppliers on a single platform</span>
                    <span className="text-black text-sm font-[400]">Real-Time Incoming Cargo Updates</span>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow2.png')} className="w-[20px] h-[20px] mr-2" />
                        <span className="text-black text-sm font-[400]">Customer Ordering system</span>
                    </div>
                </div>
                <div className="flex flex-col w-full md:w-1/2">
                    <h1 className="text-2xl text-black">Supplier</h1>
                    <span className="text-black text-sm font-[400] pr-2">Gain data-driven insight & calculate optimal order points to reduce safety inventory required, allowing for better cashflow.</span>
                    <span className="text-black text-sm font-[400]">Monitor Balance Orders, Shipment Status & Billing Details</span>
                    <div className="flex flex-row items-start mt-2">
                        <img src={require('../Assets/image/arrow2.png')} className="w-[20px] h-[20px] mr-2" />
                        <span className="text-black text-sm font-[400]">Supplier Ordering System</span>
                    </div>
                </div>
            </div>
            <button className="bg-[#0097DB] h-8 w-40 rounded-lg mt-4">
                <span className="text-sm text-white">Request for Demo</span>
            </button>
        </div>
    )
}


export default HppHome;