import React from "react";
import "./HealthInfo.css";
import { Link } from "react-router-dom";
function HealthInfo() {
  return (
    <>
      <section class="text-gray-600 body-font relative">
        <div className="container py-24 mx-auto">
          <h3 className="heading text-start text-black">
            Health Facility Registry by Avijo Alpha
          </h3>
          <div className="row">
            {" "}
            <div className="col-md-6">
              <iframe
                width="560"
                height="450"
                src="https://www.youtube.com/embed/bhjb_HHB8Ik?si=klGEALTUK9K6xRH2"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-md-6">
              <div className="health-left-sec ">
                {/* <h3 className="left-heading"> User App </h3> */}
                <p className="userApp-para">
                  The Health Facility Registry (HFR) is a comprehensive
                  repository of health facilities across India, encompassing
                  both modern and traditional systems of medicine. It includes
                  public and private health facilities such as hospitals,
                  clinics, and diagnostic centers.
                </p>
                <p className="userApp-para">
                  By registering your facility through Avijo Alpha, you join a
                  nationwide network of verified health institutions, enhancing
                  your facility's visibility and credibility. This registration
                  facilitates better access to healthcare resources, enables
                  seamless integration with other healthcare providers, and
                  ensures that patients can easily find and trust your services.
                  Embrace the digital future of healthcare and expand your reach
                  by registering with the Health Facility Registry through Avijo
                  Alpha today.
                </p>

                <div className="health-para">
                  <p> ✔ Visibility in Listings.</p>
                  <p> ✔ Management of Facility.</p>
                  <p> ✔ Smart Notifications systems.</p>
                  <p> ✔ Smart Calling System.</p>
                  <p> ✔ HIPAA Servers.</p>
                </div>
                <Link
                  to="Avijo/Alpha"
                  className="health-btn text-decoration-none pt-3 ps-3"
                >
                  Learn More / Register as HFR
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HealthInfo;
