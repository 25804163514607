import React from "react";
import "./HealthProvider.css";
import { Link } from "react-router-dom";
function HealthProvider() {
  return (
    <>
      <section class="text-gray-600 body-font relative">
        <div className="container py-24 mx-auto">
          <h3 className="heading text-start text-black">
            Health Professional Registry by Avijo Expert
          </h3>
          <div className="row">
            {" "}
            <div className="col-md-6">
              <div className="health-left-sec ">
                {/* <h3 className="left-heading"> User App </h3> */}
                <p className="userApp-para">
                  Healthcare Professionals Registry (HPR) is a comprehensive
                  repository of registered and verified different system of
                  medicines (Modern medicine, Dentistry, Ayurveda, Unani,
                  Siddha, Sowa-Rigpa, Homeopathy) and Nurses practitioners
                  delivering healthcare services across India.
                </p>
                <p className="userApp-para">
                  By joining the HPR through Avijo Expert, you gain access to a
                  network of registered professionals and can offer your
                  services more effectively across India. This registration
                  enhances your credibility, expands your reach to patients, and
                  ensures seamless interaction with other healthcare
                  practitioners. Embrace the future of healthcare by becoming a
                  part of the HPR with Avijo Expert, and contribute to
                  delivering quality healthcare to every corner of the country.
                </p>

                <div className="health-para">
                  <p> ✔ Visibility in Listings.</p>
                  <p> ✔ Management of Facility.</p>
                  <p> ✔ Smart Notifications systems.</p>
                  <p> ✔ Smart Calling System.</p>
                  <p> ✔ HIPAA Servers.</p>
                </div>
                <Link
                  to="/Avijo/Expert"
                  className="health-btn text-decoration-none pt-3 ps-3"
                >
                  Learn More / Register as HPR
                </Link>
              </div>
            </div>{" "}
            <div className="col-md-6">
              <iframe
                width="560"
                height="450"
                src="https://www.youtube.com/embed/PAIGX5n290c?si=JXkC8GQkiCMhepGN"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
              {/* <img
                src="https://s3-alpha-sig.figma.com/img/aaad/1e84/54f4390b6b90b08ac2d86b5ccf606cf7?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nDAzig4SHHmprdA8dGsULqJsOHDzQyeafF-ETSsW8~fFH2HM5B0629wP1uS8s24eJS~n~i9V9zyK67Gl599M5Eg6dJSg1Rudp55DrWtfZGpJO1hPDWziC~O-IyN0A4TbC9gzKdriuc7j~y~vGblPHgEEZknfxLRKFs-ovUOODAwbejgS7wbMcT5Bho4mgXIWJAbjIS3B5QgwZESOxiN8v-b9xDYs0VZH2xXjohh2ZuA06FuFTmvBSTutTOS8csO-NE9cO4-31yRj9qT2~puPbYq-6~5n4Yu1~hKs5u9Trq62QdwESy7RK24bvcNpCLvYP1UoQg72TjkOOpYBw8CcVA__"
                alt=""
                className="image-hero"
              /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HealthProvider;
