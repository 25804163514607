import React from "react";
import { Navbar, Container, Nav, Form } from "react-bootstrap";
import "./Header.css";

function App() {
  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="/" className="logo_navbar font-[Gilroy-SemiBold]">
          avijo
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto"></Nav>

          <Form.Control
            type="text"
            className=" search_bar"
            placeholder="Deliver To        |   Search for : Medicine & wellness products"
          />

          <Nav className="ms-auto">
            <Nav.Link href="#about" className="d-flex align-items-center">
              {" "}
              <svg
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 7V15C1 16.1046 1.89543 17 3 17H10M1 7V3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V7M1 7H19M19 7V10"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.5 18V12M15.5 12L18 14.5M15.5 12L13 14.5"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4 5C4.55228 5 5 4.55228 5 4C5 3.44772 4.55228 3 4 3C3.44772 3 3 3.44772 3 4C3 4.55228 3.44772 5 4 5Z"
                  fill="white"
                />
                <path
                  d="M7 5C7.55228 5 8 4.55228 8 4C8 3.44772 7.55228 3 7 3C6.44772 3 6 3.44772 6 4C6 4.55228 6.44772 5 7 5Z"
                  fill="white"
                />
              </svg>
              <span className="navbar_t">Upload</span>
            </Nav.Link>
            <Nav.Link href="#contact" className="d-flex align-items-center">
              <svg
                width="23"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.0500432 0.591677C0.21122 0.133263 0.713498 -0.107711 1.17192 0.0534659L1.52551 0.177771C2.24877 0.432025 2.86307 0.647975 3.34649 0.885183C3.86341 1.13882 4.30668 1.45127 4.64006 1.93913C4.97086 2.42321 5.10744 2.95431 5.17047 3.53663C5.19869 3.7973 5.21357 4.08582 5.22142 4.40296H17.7014C19.6782 4.40296 21.4576 4.40296 21.9783 5.07995C22.4992 5.75695 22.2955 6.77702 21.8883 8.81717L21.302 11.6617C20.9324 13.4553 20.7475 14.3521 20.1003 14.8795C19.453 15.407 18.5374 15.407 16.7061 15.407H10.4851C7.21335 15.407 5.57747 15.407 4.56106 14.3349C3.54465 13.2628 3.47047 12.1241 3.47047 8.67317V5.62105C3.47047 4.75299 3.46927 4.17204 3.42099 3.726C3.37485 3.29974 3.29308 3.08691 3.18719 2.93195C3.08389 2.78078 2.92707 2.6395 2.57134 2.46496C2.19261 2.27913 1.6779 2.09664 0.8947 1.82129L0.588266 1.71355C0.12984 1.55238 -0.111122 1.0501 0.0500432 0.591677Z"
                  fill="white"
                />
                <path
                  d="M6.40423 18.4805C7.37609 18.4805 8.16394 19.2683 8.16394 20.2402C8.16394 21.212 7.37609 21.9999 6.40423 21.9999C5.43237 21.9999 4.64453 21.212 4.64453 20.2402C4.64453 19.2683 5.43237 18.4805 6.40423 18.4805Z"
                  fill="white"
                />
                <path
                  d="M16.9609 18.4807C17.9327 18.4807 18.7206 19.2685 18.7206 20.2404C18.7206 21.2122 17.9327 22.0001 16.9609 22.0001C15.989 22.0001 15.2012 21.2122 15.2012 20.2404C15.2012 19.2685 15.989 18.4807 16.9609 18.4807Z"
                  fill="white"
                />
                <rect
                  x="7.5"
                  y="2"
                  width="14.0776"
                  height="14.0776"
                  rx="7.03881"
                  fill="#EE4380"
                />
                <path
                  d="M14.2888 13.6581C13.6468 13.6581 13.0999 13.4834 12.6482 13.134C12.1965 12.7817 11.8513 12.2718 11.6127 11.6042C11.374 10.9337 11.2547 10.124 11.2547 9.17518C11.2547 8.232 11.374 7.4266 11.6127 6.75899C11.8542 6.08853 12.2007 5.57717 12.6524 5.2249C13.107 4.86978 13.6524 4.69223 14.2888 4.69223C14.9252 4.69223 15.4692 4.86978 15.9209 5.2249C16.3755 5.57717 16.722 6.08853 16.9607 6.75899C17.2022 7.4266 17.3229 8.232 17.3229 9.17518C17.3229 10.124 17.2036 10.9337 16.9649 11.6042C16.7263 12.2718 16.3811 12.7817 15.9294 13.134C15.4777 13.4834 14.9309 13.6581 14.2888 13.6581ZM14.2888 12.7206C14.9252 12.7206 15.4195 12.4138 15.7718 11.8002C16.124 11.1865 16.3002 10.3115 16.3002 9.17518C16.3002 8.4195 16.2192 7.77603 16.0573 7.24478C15.8982 6.71353 15.6681 6.3087 15.3669 6.0303C15.0686 5.75189 14.7093 5.61268 14.2888 5.61268C13.6581 5.61268 13.1652 5.92376 12.8101 6.54592C12.455 7.16524 12.2774 8.04166 12.2774 9.17518C12.2774 9.93086 12.357 10.5729 12.5161 11.1013C12.6752 11.6297 12.9039 12.0317 13.2022 12.3073C13.5033 12.5829 13.8655 12.7206 14.2888 12.7206Z"
                  fill="white"
                />
              </svg>
              <span className="navbar_t">Contact</span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default App;
